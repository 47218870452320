import React from 'react';
import { useTheme } from '@mui/styles';
import { Container, Grid, Stack, Typography } from '@mui/material';
import Logo from '@assets/logo/logo-black.inline.svg';
import styles from './styles';
import { Link } from 'gatsby';

const Footer = () => {
	const theme = useTheme();

	return (
		<footer css={styles(theme)}>
			<Container sx={{ p: { lg: 10, md: 8 }, py: { xs: 4 } }} className='footer-background'>
				<Container maxWidth='lg'>
					<Grid container>
						<Grid item md={6} sx={{ mb: { xs: 4 } }}>
							<Stack spacing={2} sx={{ width: { xs: '100%', md: '80%' } }}>
								<Stack direction='row' spacing={2} alignItems='center'>
									<Link to='/' style={{ color: '#000', display: 'flex', alignItems: 'center' }}>
										<Logo style={{ width: 50, marginRight: '1rem' }} />
										<Typography variant='h6'>WAVE CARD</Typography>
									</Link>
								</Stack>
								<Typography variant='subtitle1'>
									Taking business cards and networking to the 21st Century. Currently in alpha.
								</Typography>
							</Stack>
						</Grid>
						<Grid item md={6}>
							<Grid container spacing={2} justifyContent='center'>
								<Grid item xs={12} sm={4}>
									<Stack direction='column' spacing={1}>
										<Typography variant='caption' className='footer-title'>
											CARDS
										</Typography>

										<Typography variant='subtitle1'>
											<Link to='/dashboard/account'>My Account</Link>
										</Typography>

										<Typography variant='subtitle1'>
											<Link to='/help'>Support</Link>
										</Typography>

										<Typography variant='subtitle1'>
											<Link to='/checkout'>Create</Link>
										</Typography>

										<Typography variant='subtitle1'>
											<Link to='/dashboard/cards'>Card</Link>
										</Typography>
									</Stack>
								</Grid>
								<Grid item xs={12} sm={4}>
									<Stack direction='column' spacing={1}>
										<Typography variant='caption' className='footer-title'>
											LEGALS
										</Typography>

										<Typography variant='subtitle1'>
											<Link to='#'>License</Link>
										</Typography>

										<Typography variant='subtitle1'>
											<Link to='#'>Refund Policy</Link>
										</Typography>

										<Typography variant='subtitle1'>
											<Link to='#'>About Us</Link>
										</Typography>

										<Typography variant='subtitle1'>
											<Link to='#'>Contacts</Link>
										</Typography>
									</Stack>
								</Grid>
								<Grid item xs={12} sm={4}>
									<Stack direction='column' spacing={1}>
										<Typography variant='caption' className='footer-title'>
											CONTACTS
										</Typography>

										<Typography variant='subtitle1'>
											Get in touch with us, we're happy to chat
										</Typography>

										{/*<Typography variant="subtitle1">*/}
										{/*	+1 234 567 89 10*/}
										{/*</Typography>*/}

										<Typography variant='subtitle1'>
											support@wavecard.io
										</Typography>
									</Stack>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Container>
		</footer>
	);
};

export default Footer;
