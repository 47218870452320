import { css } from '@emotion/react';

export default (theme) => css`
	margin-top: auto;
	flex-shrink: 1;
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(237, 237, 237, 1) 40%,
		rgba(210, 210, 210, 1) 100%
	);
	.footer-title {
		color: #2962ff;
	}

	.MuiTypography-subtitle1 {
		color: #000000;
		font-weight: 500;
	}

	.MuiTypography-subtitle1 > * {
		color: #000;
	}
`;
