import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	position: relative;
	z-index: 10;

	&.header-wrapper-absolute {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}

	.header {
		box-shadow: none;
		padding: ${theme.spacing(3, 0)};

		&.header-help-page {
			background-color: ${theme.palette.primary.main};
		}

		&.header-inversed {
			.header-logo {
				color: ${theme.palette.common.white};
			}

			.navigation {
				color: ${theme.palette.common.white};

				.navigation-item {
					.navigation-item-link {
						color: ${theme.palette.common.white};
					}
				}
			}

			.header-account {
				.header-account-name {
					color: ${theme.palette.common.white};
				}
			}
		}

		.header-logo {
			display: flex;
			align-items: center;

			svg {
				width: 40px;
				height: auto;
			}

			span {
				margin-left: ${theme.spacing(2)};
				text-transform: uppercase;
				font-weight: 700;
				letter-spacing: 0.12em;
			}
		}

		.navigation {
			.navigation-item {
				.navigation-item-link {
					text-decoration: none;
					// text-shadow: 2px 2px 5px #333;
					font-weight: 700;
					// color: ${theme.palette.text.primary};
				}
			}

			.navigation-iconBtn {
				color: ${theme.palette.common.black};
			}

			.icon-inversed {
				color: ${theme.palette.common.white};
			}
		}

		.header-account {
			display: flex;
			align-items: center;

			.header-account-avatar {
				height: 30px;
				width: 30px;
			}

			.header-account-name {
				font-weight: bold;
				color: ${theme.palette.text.primary};
			}
		}
	}

	.avatar-image {
		height: 40px;
		width: 40px;
		border-radius: 50px;
		border: 2px solid white;
		opacity: 1;
		transition: opacity ease-in-out 0.1s;

		&:hover,
		&:focus {
			border: 2px solid #eee;
			opacity: 0.8;
		}
	}
`;
