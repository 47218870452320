import React, { useState } from 'react';
import styles from './styles';
import {
	AppBar,
	Avatar,
	Button,
	Container,
	Drawer,
	Grid,
	Toolbar,
	Typography,
	Link,
	List,
	ListItem,
	Menu,
	MenuItem,
	IconButton,
	Box,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme, makeStyles } from '@mui/styles';
import { Link as RouterLink } from 'gatsby';
import { useSelector } from 'react-redux';
import LogoBlack from '@assets/logo/logo-black.inline.svg';
import LogoWhite from '@assets/logo/logo-white.inline.svg';
import { USER_AVATAR } from '@helpers/api';
import SideMenu from '@components/SideMenu';
import { useLocation } from '@reach/router';

const useStyles = makeStyles((theme) => ({
	paper: {
		width: '260px',
		padding: theme.spacing(1),
	},
	drawerList: {
		height: '100vh',
		display: 'flex',
		flexDirection: 'column',
	},
	logo: {
		width: '30%',
		marginRight: theme.spacing(1),
	},
	link: {
		fontWeight: 600,
		margin: theme.spacing(2, 0),
		textDecoration: 'none',
	},
	name: {
		fontWeight: 600,
	},
	listItemBtn: {
		display: 'contents',
	},
	button: {
		width: '90%',
		margin: 'auto auto 0 auto',
		padding: theme.spacing(1, 2),
		textTransform: 'capitalize',
	},
}));

const Header: React.FC<{ absolute?: boolean; innerRef?: any; inversed?: boolean }> = ({
	absolute,
	innerRef,
	inversed,
}) => {
	const theme = useTheme();
	const classes = useStyles();
	const { user, darkMode, navigation } = useSelector(({ user, darkMode, navigation }) => ({
		user,
		darkMode,
		navigation,
	}));

	const [userDropdownOpen, setUserDropdownOpen] = useState<boolean>(false);
	const [openDrawer, setOpenDrawer] = useState<boolean>(false);
	const $avatar: any = React.useRef(null);
	const location = useLocation();

	return (
		<div
			ref={innerRef}
			css={styles(theme)}
			className={absolute ? 'header-wrapper-absolute' : ''}
		>
			<AppBar
				position="static"
				color="transparent"
				className={`header ${inversed ? 'header-inversed' : ''}`}
				sx={{
					backgroundColor: location.pathname.includes('/help')
						? theme.palette.primary.main
						: '',
				}}
			>
				<Toolbar>
					<Container>
						<Grid
							justifyContent="space-between"
							container
							spacing={8}
							alignItems="center"
							sx={{
								backgroundColor:
									location.pathname === '/help' ? theme.palette.primary.main : '',
							}}
						>
							<Grid item>
								<RouterLink to="/">
									<Typography
										className="header-logo"
										component="h1"
										variant="h6"
										noWrap
									>
										{inversed || location.pathname.includes('/help') ? (
											<LogoWhite />
										) : (
											<LogoBlack />
										)}
										<span
											style={{
												color:
													inversed || location.pathname.includes('/help')
														? '#fff'
														: '#000',
											}}
										>
											Wave Card
										</span>
									</Typography>
								</RouterLink>
							</Grid>

							<Grid item xs className="navigation">
								<Grid
									container
									spacing={12}
									justifyContent="flex-end"
									alignItems="center"
								>
									<Grid item>
										<Box sx={{ display: { md: 'none', xs: 'inline-block' } }}>
											<IconButton
												className={`navigation-iconBtn ${
													inversed ? 'icon-inversed' : ''
												}`}
												onClick={() => setOpenDrawer(!openDrawer)}
											>
												<MenuIcon
													color="inherit"
													sx={{
														color: location.pathname.includes('/help')
															? '#fff'
															: '',
													}}
												/>
											</IconButton>
											<Drawer
												anchor="left"
												open={openDrawer}
												onClose={() => setOpenDrawer(false)}
												className={classes.paper}
												classes={{
													paper: classes.paper,
												}}
											>
												<List className={classes.drawerList}>
													<ListItem button>
														<RouterLink to="/">
															<Typography
																className="header-logo"
																component="h1"
																variant="h6"
																noWrap
																sx={{
																	display: 'flex',
																	alignItems: 'center',
																}}
															>
																{darkMode ? (
																	<LogoWhite
																		className={classes.logo}
																	/>
																) : (
																	<LogoBlack
																		className={classes.logo}
																	/>
																)}
																<span
																	style={{
																		color: darkMode
																			? theme.palette.common
																					.white
																			: theme.palette.common
																					.black,
																		fontSize: '0.8rem',
																		textTransform: 'uppercase',
																		letterSpacing: '0.12rem',
																	}}
																>
																	Wave Card
																</span>
															</Typography>
														</RouterLink>
													</ListItem>
													<Link
														className={classes.link}
														component={RouterLink}
														to="/checkout"
														sx={{
															color: darkMode
																? theme.palette.common.white
																: theme.palette.common.black,
														}}
													>
														<ListItem button>Create</ListItem>
													</Link>
													<Link
														className={classes.link}
														component={RouterLink}
														to="/pricing"
														sx={{
															color: darkMode
																? theme.palette.common.white
																: theme.palette.common.black,
														}}
													>
														<ListItem button>Pricing</ListItem>
													</Link>
													<Link
														className={classes.link}
														component={RouterLink}
														to="/help"
														sx={{
															color: darkMode
																? theme.palette.common.white
																: theme.palette.common.black,
														}}
													>
														<ListItem button>Support</ListItem>
													</Link>
													<ListItem
														button
														className={classes.listItemBtn}
													>
														{!!user ? (
															// <SideMenu
															// 	user={user}
															// 	navigation={navigation}
															// />
															<Grid
																justifyContent="flex-start"
																alignItems="center"
																container
																spacing={2}
															>
																<Grid
																	item
																	container
																	justifyContent="space-around"
																	alignItems="center"
																	onClick={() =>
																		setUserDropdownOpen(
																			!userDropdownOpen
																		)
																	}
																>
																	<IconButton
																		ref={$avatar}
																		aria-controls={
																			navigation?.open
																				? 'menu-list-grow'
																				: undefined
																		}
																		aria-haspopup="true"
																		className="avatar"
																	>
																		<Avatar
																			className="avatar-image"
																			alt={`${user?.firstName}'s avatar`}
																			src={`${USER_AVATAR(
																				user?._id
																			)}`}
																		/>
																	</IconButton>
																	<Typography
																		className={classes.name}
																	>
																		{user?.firstName}{' '}
																		{user?.lastName}
																	</Typography>
																	<ExpandMoreIcon />
																</Grid>
																<Menu
																	anchorEl={$avatar?.current}
																	anchorOrigin={{
																		vertical: 'bottom',
																		horizontal: 'center',
																	}}
																	transformOrigin={{
																		vertical: 'top',
																		horizontal: 'center',
																	}}
																	keepMounted
																	open={userDropdownOpen}
																	onClose={() =>
																		setUserDropdownOpen(false)
																	}
																	onClick={() =>
																		setUserDropdownOpen(false)
																	}
																>
																	<MenuItem
																		component={RouterLink}
																		to="/dashboard"
																	>
																		Dashboard
																	</MenuItem>
																	<MenuItem
																		component={RouterLink}
																		to="/dashboard/account"
																	>
																		Edit Account
																	</MenuItem>
																	<MenuItem
																		component={RouterLink}
																		to="/logout"
																	>
																		Logout
																	</MenuItem>
																</Menu>
															</Grid>
														) : (
															<Button
																to="/login"
																component={RouterLink}
																variant="contained"
																color="primary"
																className={classes.button}
															>
																Sign in
															</Button>
														)}
													</ListItem>
												</List>
											</Drawer>
										</Box>

										<Box
											sx={{
												display: { md: 'flex', xs: 'none' },
												width: '500px',
											}}
										>
											<Grid
												container
												spacing={6}
												sx={{ alignItems: 'center' }}
											>
												<Grid item className="navigation-item">
													<Link
														className="navigation-item-link"
														component={RouterLink}
														to="/checkout"
														sx={{
															color:
																location.pathname.includes(
																	'/pricing'
																) ||
																location.pathname.includes(
																	'/affiliate'
																)
																	? theme.palette.common.black
																	: theme.palette.common.white,
														}}
													>
														Create
													</Link>
												</Grid>

												<Grid item className="navigation-item">
													<Link
														className="navigation-item-link"
														component={RouterLink}
														to="/pricing"
														sx={{
															color:
																location.pathname.includes(
																	'/pricing'
																) ||
																location.pathname.includes(
																	'/affiliate'
																)
																	? theme.palette.common.black
																	: theme.palette.common.white,
														}}
													>
														Pricing
													</Link>
												</Grid>

												<Grid item className="navigation-item">
													<Link
														className="navigation-item-link"
														component={RouterLink}
														to="/help"
														sx={{
															color:
																location.pathname.includes(
																	'/pricing'
																) ||
																location.pathname.includes(
																	'/affiliate'
																)
																	? theme.palette.common.black
																	: theme.palette.common.white,
														}}
													>
														Help
													</Link>
												</Grid>
											</Grid>
											<Grid
												container
												justifyContent="flex-end"
												className="navigation-item right-side"
											>
												{!!user ? (
													<SideMenu user={user} navigation={navigation} />
												) : (
													<Button
														to="/login"
														component={RouterLink}
														variant={
															!inversed &&
															theme.palette.mode === 'dark'
																? 'contained'
																: 'dark'
														}
														className="button"
													>
														Sign in
													</Button>
												)}
											</Grid>
										</Box>
									</Grid>

									{/*	<Avatar*/}
									{/*		className="header-account-avatar"*/}
									{/*		alt={`${user?.firstName ?? ''} ${*/}
									{/*			user?.lastName ?? ''*/}
									{/*		}`}*/}
									{/*		src={*/}
									{/*			'/fake-profile/joe-methven.jpg' ??*/}
									{/*			user?.avatar ??*/}
									{/*			''*/}
									{/*		}*/}
									{/*	/>*/}
									{/*	<Typography className="header-account-name">{`${*/}
									{/*		user?.firstName ?? ''*/}
									{/*	} ${user?.lastName ?? ''}`}</Typography>*/}
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</Toolbar>
			</AppBar>
		</div>
	);
};

export default React.forwardRef((props, ref) => <Header {...props} innerRef={ref} />);
